import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import DatePicker from '../utils/DatePicker';
import {cleanCSVText, convertTimeStampToHumanReadable, getSnapshotFromEndpoint, findFirstAndLastName, validUrl} from '../utils/HelpfulFunction';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pledge_select: {},
          ticket_select: {},
          ticketList: [],
          users: [],
          pledgeList: [],
          schedule_game_time: new Date(),
          schedule_game_time_end: new Date(),
          schedule_game: false,
          pledgesAdded: [],
          emailVariables: {},
          rewardsAdded: [],
          rewardToAdd: '',
          rewardAmount: 0,
          codes: false,
          codesArray: '',
          pinsArray: '',
          qrCodes: false,
          userPicks: [],
          tenantRules: {},
          activateGame: {},
          currentGameRewards: [],
          currentGame: null,
          modal: false,
          gameName: null,
          userGameHistory: [],
          loading: true,
          wizardLocation: 'first',
          showStats: true,
          advanceSettings: false,
          gamesList: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

    componentDidMount() {
      const vm = this;
      this.pledgeListRef = base.bindToState(`pledgeList`, {
        context: this,
        state: 'pledgeList',
        asArray: true
      });
      this.emailVariablesRef = base.bindToState(`emailVariables`, {
        context: this,
        state: 'emailVariables',
      });
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.userAnswersRef = appDatabasePrimaryFunctions.ref("userPicks").on('value', function(snapshot){
        vm.setState({
          userAnswerCount: snapshot.numChildren()
        })
      })
      this.currentTasksRef = base.syncState(`tasks/activateGame`, {
        context: this,
        state: 'activateGame',
      });
      this.currentGameRef = base.syncState(`currentGame`, {
        context: this,
        state: 'currentGame',
        then(err){
          this.setState({
            loading: false,
          })
          if(!err){
            this.setGameToWatch(this.state.currentGame.id)
          }
        },
        onFailure(err){
          // console.log(err)
          this.setState({
            loading: false,
          })
        }
      })
      this.ticketListRef = base.bindToState(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
    }

    downloadUsers(){
      this.setState({loading: true});
      const vm = this;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      let firstColumnName = "Email";
      if(isMlbApp){
        firstColumnName = "Okta"
      }
      let csv = `${firstColumnName},First Name,Last Name,Zip Code,Birthday,Opt-In,Pick,Time Picked,Reward,Code\n`;
      Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
        let users = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          users = snapshots[0].val();
        }
        const currentUsersState = await vm.sortPlayersForAdmin();
        currentUsersState.forEach(function(row) {
          let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
          csv += (row.email || row.uid || "");
          csv+= ",";
          csv+= (cleanCSVText(firstName) || "");
          csv+= ",";
          csv+= (cleanCSVText(secondName) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].zipCode) || "");
          csv+= ",";
          csv+= (users[row.uid].birthday || "");
          csv+= ",";
          csv+= (users[row.uid].optIn || "");
          csv+= ",";
          csv+= (cleanCSVText(row.pick) || "");
          csv+= ",";
          csv+= convertTimeStampToHumanReadable(row.timeStamp);
          csv+= ",";
          csv+= (cleanCSVText(row.rewardSent) || "NONE");
          csv+= ",";
          csv+= (cleanCSVText(row.code) || "-");
          csv += "\n";
        }, this);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_game_users_" + date_got;
        hiddenElement.click();
        vm.setState({loading: false});
      })
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      if(id){
        const vm = this;
        this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
          vm.setState({
            userGameHistory: snapshot.numChildren()
          })
        })
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.pledgeListRef);
      base.removeBinding(this.currentGameRef);
      base.removeBinding(this.ticketListRef);
      base.removeBinding(this.emailVariablesRef);
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.tenantRulesRef);
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
      base.removeBinding(this.currentTasksRef);
    }

    createGame(){
      const createGameObject = {};
      const gameId = appDatabasePrimaryFunctions.ref().push().key;
      const pledgesAdded = this.state.pledgesAdded;
      const rewardsAdded = this.state.rewardsAdded || [];
      const futureGames = this.state.futureGamesList || [];
      const gameName = this.state.gameName;
      let gameStartTime = this.state.schedule_game_time;
      let gameEndTime = this.state.schedule_game_time_end;
      const scheduledGame = this.state.schedule_game;
      const currentTimeSeconds = Math.floor(Date.now());
      let inputtedDateSeconds = null;
      if(gameStartTime){
        inputtedDateSeconds = gameStartTime.getTime();
      }
      let gameEndTimeSeconds = null;
      if(gameEndTime){
        gameEndTimeSeconds = gameEndTime.getTime();
      }
      if(!gameName){
        swal({
            title: "Hold On!",
            text: "You must give the game a name!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(scheduledGame){
        if(inputtedDateSeconds <= currentTimeSeconds){
          swal({
              title: "Hold On!",
              text: "You have marked that you are scheduling a game but it is before now!",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        } else if(!inputtedDateSeconds && !gameEndTimeSeconds) {
          swal({
            title: "Hold On!",
            text: "You have marked that you are scheduling but no game time entered!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        } else if(inputtedDateSeconds && gameEndTimeSeconds){
            if(gameEndTimeSeconds <= inputtedDateSeconds){
              swal({
                title: "Hold On!",
                text: "End time before or equal to start time",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
              return;
            }
        } else if(gameEndTimeSeconds && gameEndTimeSeconds <= currentTimeSeconds) {
          swal({
            title: "Hold On!",
            text: "End time cannot be before the current time",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
        for(const fGameIndex in futureGames){
          const futureGame = futureGames[fGameIndex];
          if(inputtedDateSeconds === futureGame.activateGame.performAt){
            swal({
              title: "Hold On!",
              text: "You already have a scheduled game at that time!",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
            return;
          }
        }
      }
      if(pledgesAdded && pledgesAdded.length < 1){
        const textToFill= "pledges";
        swal({
            title: "Hold On!",
            text: `Not enough ${textToFill} added. You need to add at least 1 ${textToFill} to the game!`,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      let readyToUploadPrizes = null;
      let totalRewardsCount = 0;
      if(rewardsAdded.length !== 0 && !this.state.noPrizes){
        readyToUploadPrizes = {};
        for(const rewardIndex in rewardsAdded){
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key] = rewardsAdded[rewardIndex].reward;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['amount'] = parseInt(rewardsAdded[rewardIndex].amount);
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codes'] = rewardsAdded[rewardIndex].codes;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['pinsArray'] = rewardsAdded[rewardIndex].pinsArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['used'] = 0;
          totalRewardsCount+=parseInt(rewardsAdded[rewardIndex].amount)
        }
      }

      createGameObject.pledges = pledgesAdded;
      createGameObject.rewards = readyToUploadPrizes;
      createGameObject.totalRewardsUsed = 0;
      createGameObject.totalRewards = totalRewardsCount;
      createGameObject.gameName = gameName;
      createGameObject.active = false;
      createGameObject.firstStart = false;
      createGameObject.gameType = "pledge";
      let activateGame = {};
      let shouldReplaceCurrentGame  = true
      const currentGame = this.state.currentGame || {};
      if(scheduledGame){
        activateGame['performAt'] = inputtedDateSeconds;
        activateGame['status'] = "scheduled";
        activateGame['endAt'] = gameEndTimeSeconds;
        if(currentGame.gameName){
          shouldReplaceCurrentGame = false
          createGameObject['activateGame'] = activateGame
        }
      } else {
        activateGame = null
      }
      this.setState({
        loading: true
      })
      createGameObject.timeStamp = Date.now()
      createGameObject.id = gameId;
      if(shouldReplaceCurrentGame){
        this.saveDeleteCurrentGameDataReplace(currentGame, activateGame, createGameObject);
      } else if(createGameObject.id) {
        const vm = this;
        base.post('futureGamesList/'+ createGameObject.id, {
          data: createGameObject,
          then(err){
            if(!err){
              vm.setState({
                modal: false
              })
            }
            vm.setState({
              loading: false
            })
          }
        })
      }
    }

    async saveDeleteCurrentGameDataReplace(currentGame, activateGame, createGameObject, fromMakeFutureGameCurrent=false){
      if(currentGame.gameName){
        currentGame['users'] = this.state.userGameHistory;
        const emailsSentCount = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function(snapshot){
            return snapshot.numChildren();
        })
        const prizesWon = emailsSentCount || 0
        let userPicksCount = await appDatabasePrimaryFunctions.ref('userPicks').once('value').then(function(snapshot){
          return snapshot.numChildren();
        })
        currentGame['prizes'] = prizesWon;
        currentGame['answers'] = userPicksCount;
        const organizeUserAnswers = await this.sortPlayersForAdmin();
        base.post(`userAnswersHistory/${currentGame.id}`, {
          data: organizeUserAnswers,
          then(data){
            console.log("User Answers Saved!")
          }
        })
      }

      await base.remove("prizeWon");
      await base.remove("currentGame/rewards");
      await base.remove("userPicks");

      const vm = this;
      base.push('gamesList', {
        data: currentGame,
        then(err){
          if(!err){
            vm.setState({
              currentGame: createGameObject,
              activateGame: activateGame,
              currentUserPicksState: [],
              modal: false,
              loading: false
            }, ()=>{
              base.remove("emailsSent");
              if(fromMakeFutureGameCurrent){
                vm.removeGameFromFutureList(createGameObject.id)
              }
            })
          } else {
            vm.setState({
              loading: false
            })
          }
        }
      })
    }

    makeFutureGameCurrent(gameToSetCurrent){
      const vm = this;
      const currentGame = this.state.currentGame;
      swal({
        title: 'STOP!',
        text: 'Are you sure you want to do this?  This will replace the current game!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'YES',
      }).then(async (result)=>{
        if(result.value){
          vm.saveDeleteCurrentGameDataReplace(currentGame, gameToSetCurrent['activateGame'], gameToSetCurrent, true);
        }
      })
    }

    async removeGameFromFutureList(gameToRemoveId, showAlert=false){
      let response = true;
      if(showAlert){
        let swalResponse = await swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will delete the future game!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        })
        response = swalResponse.value;
      }
      if(response){
        var futureGameList = this.state.futureGamesList;
        for(var gameIndex in futureGameList){
          var futureGame = futureGameList[gameIndex];
          if(futureGame.id === gameToRemoveId){
            futureGameList.splice(gameIndex, 1)
            if(gameToRemoveId){
              base.remove('futureGamesList/'+gameToRemoveId)
            }
          }
        }
        this.setState({
          futureGamesList: futureGameList
        })
      }
    }

    startGame(){
      const currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = true;
        currentGame.firstStart = true;
        currentGame.timeStamp = Date.now();
        this.setState({
          currentGame: currentGame
        })
      } else {
        swal({
          title: "Hold On!",
          text: "Create a game to start a game!",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
    }

    stopGame(){
      const currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = false
        this.setState({
          currentGame: currentGame
        })
      }
    }

    reset(){
      const currentGame = this.state.currentGame;
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will reset peoples picks!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        }).then(async (result)=>{
          if(result.value){
            currentGame.active = false;
            currentGame.firstStart = false;
            currentGame.totalRewardsUsed = 0;
            for(const index in currentGame.rewards){
              if(typeof currentGame.rewards[index] === 'object'){
                currentGame.rewards[index].used = 0
              }
            }
            this.setState({
              currentGame: currentGame,
              currentUserPicksState: []
            })
            await base.remove("prizeWon");
            await base.remove("userPicks");
            var history = this.state.userGameHistory;
            for(var historyRef in history){
              var refInstance = history[historyRef];
              base.post(`userGameHistory/${refInstance.key}/${currentGame.id}`, {
                data: null,
                then(err){
                  if(!err){
                    console.log("reset user history")
                  }
                }
              })
            }
            base.post(`emailsSent`, {
              data: null,
              then(err){
                if(!err){
                  console.log("reset emails sent")
                }
              }
            })
            swal({
              title: 'Reset Successful',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first"
      });
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
      if(buttonClicked === "showStats"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:true,
          showPrizes:false,
          showGames:false
        })
      }else if(buttonClicked === "showPrizes"){
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        this.setState({
          loading: true
        })
        const organizeUserAnswers = await this.sortPlayersForAdmin();
        this.setState({
          showStats:false,
          showPrizes:true,
          showGames:false,
          loading: false,
          currentUserPicksState: organizeUserAnswers
        })
      } else if(buttonClicked === "showGames"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        const futureGameList = await this.getFutureGames();
        this.setState({
          showStats:false,
          showPrizes:false,
          showGames:true,
          futureGamesList: futureGameList
        })
      }
    }

    removeFromToAddArray(index, name){
      let removeArray = this.state[name];
      removeArray.splice(index, 1)
      this.setState({
        [name]: removeArray
      })
    }

    addPledgeToLocalArray(){
      const pledgesAddedArray = this.state.pledgesAdded;
      const pledgeList = this.state.pledgeList;
      const pledgeKeys = []
      for(const index in pledgesAddedArray){
        pledgeKeys.push(pledgesAddedArray[index].key)
      }
      if(pledgeKeys.indexOf(this.state.pledge_select) !== -1){
        swal({
            title: `Already In Game!`,
            text: `This game already includes this item. Try adding another item.`,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      let pledge = {}
      for(const pledgeIndex in pledgeList){
        if(pledgeList[pledgeIndex].key === this.state.pledge_select){
            pledge = pledgeList[pledgeIndex]
          pledge.id = this.state.pledge_select
        }
      }
      if(pledge.id){
         pledgesAddedArray.push(pledge)
         this.setState({
           pledgesAdded: pledgesAddedArray
         })
      } else {
        swal({
            title: "Nothing Selected!",
            text: "Make sure to select an option before clicking 'Add'",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
      }
    }

    async sortPlayersForAdmin(numberOfResults=0){
      const currentGame = this.state.currentGame || {};
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const promiseArray = await Promise.all([getSnapshotFromEndpoint('emailsSent', appDatabasePrimaryFunctions)]).then(async function(snapshots){
        return [snapshots[0].val()]
      })
      const emailsSent = promiseArray[0];
      let userAnswersRef = appDatabasePrimaryFunctions.ref("userPicks").orderByChild('timeStamp');
      if(numberOfResults) {
        userAnswersRef = userAnswersRef.limitToFirst(numberOfResults);
      }
      const userAnswers = await userAnswersRef.once('value').then( function(userSnapshot){
        return userSnapshot;
      })
      const tempUserAnswerArray = [];
      userAnswers.forEach(function(userAnswerRef){
        tempUserAnswerArray.push(userAnswerRef.val())
      })
      const usersPicksAttempted = [];
      for(const i in tempUserAnswerArray){
        const user = {};
        const pick = tempUserAnswerArray[i];
        let successConverting = false;
        let notEmails = false;
        let code = "";
        let rewardName = "";
        let sentEmailObjects = {};
        sentEmailObjects = emailsSent;

        if(sentEmailObjects && sentEmailObjects[pick.uid]){
          code = sentEmailObjects[pick.uid].barcode || sentEmailObjects[pick.uid].code || "";
          rewardName = sentEmailObjects[pick.uid].name || sentEmailObjects[pick.uid].rewardName || "Yes";
        }
        let timeStamp = pick.timeStamp;
        let pickName = "No"
        for(let pledgeIndex in currentGame.pledges){
          const pledge = currentGame.pledges[pledgeIndex];
          if(pledge.id === pick.pickid){
            pickName = pledge.pledgeName
          }
        }
        let userEmail = pick.uid;
        if(!notEmails && !isMlbApp){
          if(!successConverting){
            try {
              userEmail = atob(userEmail);
              successConverting = true;
            } catch (e) {
              notEmails = true;
            }
          } else {
            userEmail = atob(userEmail);
          }
        }
        user['timeStamp'] = timeStamp;
        user['pick'] = pickName;
        user['email'] = userEmail;
        user['rewardSent'] = rewardName;
        user['uid'] = pick.uid;
        user['code'] = code;
        usersPicksAttempted.push(user)
      }
      const cmp = (a, b) => (a > b) - (a < b)
      usersPicksAttempted.sort(function(a, b) {
        return cmp(a.timestamp,b.timestamp)
      })
      return usersPicksAttempted
    }

    async getFutureGames(numberOfResults = 10){
      return await base.fetch('futureGamesList', {
        context: this,
        asArray: true,
        queries: {
          orderByChild: 'activateGame/performAt'
        },
        then(data){
          return data
        }
      })
    }

    saveReward (evt) {
      this.setState({ rewardToAdd: evt.target.value });
    }

    detectHowManyCodesEntered(codesArray, areLinks){
      if(!codesArray){
        return [];
      }
      const splitOnLineBreaks = codesArray.split("\n");
      let splitOnWhiteSpace = codesArray.split(/\s+/);
      let splitOnCommas = codesArray.split(",");
      let splitArray = splitOnLineBreaks;
      if(splitOnWhiteSpace.length === splitOnCommas.length){
        splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
        splitOnCommas = codesArray.replace(/\s/g,'').split(",");
      }
      if(splitArray.length < splitOnWhiteSpace.length){
        splitArray = splitOnWhiteSpace
      }
      if(splitArray.length < splitOnCommas.length){
        splitArray = splitOnCommas
      }
      if(areLinks){
        for(const linkIndex in splitArray){
          const link = splitArray[linkIndex];
          if(!validUrl(link)){
            return {message:"Invalid Url", inValidUrl: link};
          }
        }
      }
      return splitArray
    }

    addRewardToLocalArray(){
      const rewardAmount = this.state.rewardAmount;
      const rewardId = this.state.rewardToAdd;
      const codes = this.state.codes;
      let codesArray = this.state.codesArray;
      let pinsArray = this.state.pinsArray;
      const qrCodes = this.state.qrCodes;
      codesArray = this.detectHowManyCodesEntered(codesArray);
      pinsArray = this.detectHowManyCodesEntered(pinsArray);
      if(codesArray.length === 0 && codes){
        swal({
          title: 'Hold on!',
          text: 'Must enter codes or uncheck codes box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (codes){
        if(codesArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of codes must equal number of prizes created. In this case: " +  codesArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        } else if(pinsArray.length !== 0 && pinsArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Pins not equal!',
            text: "# of pins must equal number of prizes created. In this case: " +  pinsArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
        swal({
          title: 'Missing field!',
          text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }

      for(var ticket in this.state.ticketList){
        if(this.state.ticketList[ticket].key === rewardId){
          var reward = this.state.ticketList[ticket]
          var rewardToAddObject = {};
          rewardToAddObject.reward = reward;
          rewardToAddObject.amount = rewardAmount;
          rewardToAddObject.codes = codes;
          rewardToAddObject.codesArray = codesArray;
          rewardToAddObject.pinsArray = pinsArray;
          rewardToAddObject.qrCodes = qrCodes;
        }
      }
      var array = this.state.rewardsAdded;
      var rewardsAddedIds = []
      for(var prize in array){
        rewardsAddedIds.push(array[prize].reward.key)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          rewardsAdded: array,
          qrCodes: false,
          codes: false
        })
      } else {
        swal({
          title: 'Prize already added!',
          text: "You have already added this prize",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
    }

    render() {
      let selectedGame = this.state.currentGame || {};
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const futureGamesList = this.state.futureGamesList || [];
      const userAnswerCount = this.state.userAnswerCount || 0;
      const tenantVariables = this.state.tenantVariables || {};
      let gameActive;
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      let showTime = false;
      const rewardsList = [];
      const rewardsAdded = this.state.rewardsAdded || [];
      let currentUserPicksState = this.state.currentUserPicksState || [];
      if(selectedGame && selectedGame.gameName){
        for(const i in selectedGame.rewards){
          const reward = selectedGame.rewards[i];
          if(typeof reward === "object"){
            rewardsList.push(reward);
          }
        }
        gameActive = selectedGame.active;
        if(selectedGame.activateGame){
          // startTimeFirstPart = new Date(selectedGame.activateGame.performAt).toLocaleDateString();
          // startTimeSecondPart = new Date(selectedGame.activateGame.performAt).toLocaleTimeString();
          endTimeFirstPart = new Date(selectedGame.activateGame.endAt).toLocaleDateString();
          endTimeSecondPart = new Date(selectedGame.activateGame.endAt).toLocaleTimeString();
          showTime = true;
        }
      } else {
        selectedGame = null;
        gameActive = false;
      }
      const activateGameObject = this.state.activateGame || {};
      let currentScheduleStatus = ""
      let currentScheduleTime = ""
      if(activateGameObject.performAt){
        currentScheduleStatus = activateGameObject.status;
        currentScheduleTime = convertTimeStampToHumanReadable(activateGameObject.performAt);
      } else {
        currentScheduleStatus = "Manual"
        currentScheduleTime = "N/A"
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                <center className="col-md-1" style={{display: gameActive ? '' : 'none', color:'black'}}>
                  Advanced<input id="advanceSettings" name="advanceSettings" type="checkbox" checked={this.state.advanceSettings} onChange={this.handleChange} />
                </center>
                <div className="col-md-2">
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive && this.state.advanceSettings ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="col-md-2" style={{color:"black", visibility: currentScheduleStatus === "Manual" ? "hidden":""}}>
                  <center>Status: {currentScheduleStatus}</center>
                  <center>Scheduled Time: {currentScheduleTime}</center>
                </div>
                <div className="col-md-3">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.reset()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft:10, marginTop: 10, marginBottom:10, borderRadius: 5, display: !showTime && "none"}}>
                    <div className="row">
                      {/*<div className="col-md-6">*/}
                      {/*  <u style={{color:"#353839"}}>Starts</u>*/}
                      {/*  <br/>*/}
                      {/*  <span style={{fontSize:18}}>{startTimeFirstPart || "No Start Time"}<br/>{startTimeSecondPart}</span>*/}
                      {/*</div>*/}
                      <div className="col-md-12">
                        <u style={{color:"#353839"}}>Ends</u>
                        <br/>
                        <span style={{fontSize:18}}>{endTimeFirstPart || "No End Time"}<br/>{endTimeSecondPart}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft: 10, marginTop:10, marginBottom:10, borderRadius: 5}}>
                    <div className="row">
                      <div className="col-md-12" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>Current Game Players</span>
                        <footer className="value-text">{userAnswerCount}</footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                  {!isMlbApp &&
                    <div className="export-button-styles btn btn-primary btn-lg download-button" style={{marginBottom:10}} onClick={()=>this.downloadUsers()}>
                      <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                  }
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>{tenantVariables.doNotCollectEmail || isMlbApp ? "UID" : "Email"}</th>
                        <th>Pick</th>
                        <th>Time Stamp</th>
                        <th>Reward</th>
                        <th>Code</th>
                      </tr>
                      {
                        currentUserPicksState.map(function(item,i){
                            const timeStamp = convertTimeStampToHumanReadable(item.timeStamp)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{tenantVariables.doNotCollectEmail || isMlbApp ? item.uid : item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.pick}</td>
                              <td style={{fontFamily:'Open Sans' }}>{timeStamp}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Rewards</h4>
                        {rewardsList.length > 0 ?
                            rewardsList.map(function(item, index){
                              return (
                                  <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                    <div className="col-md-6">
                                      {item.rewardName}
                                    </div>
                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                      Amount: {item.amount}
                                    </div>
                                  </div>
                              )
                            })
                            :
                            <div style={{textAlign:'left', color:'black'}}>
                              <p>No Rewards Added</p>
                            </div>
                        }
                      </div>
                      <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Details</h4>
                        {selectedGame && selectedGame.gameName &&
                          <div style={{textAlign:'left', color:'black'}}>
                            Game Name: {selectedGame.gameName}
                            {selectedGame.pledges &&
                              selectedGame.pledges.map(function(item, index){
                                return (
                                    <div key={index} style={{marginTop: 5}}>
                                      <p>Pledge: <img src={item.gifSelectionImage} width="50px" height="auto" alt=""/>{item.pledgeName}</p>
                                    </div>
                                )
                              }, this)
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>End Time</th>
                        <th>Active</th>
                        <th>Emails Sent</th>
                        <th>Make Current Game</th>
                        <th>Delete Game</th>
                      </tr>
                      {
                        futureGamesList.map(function(item,i){
                          var dateTime = convertTimeStampToHumanReadable(item.activateGame.performAt)
                          let showEndTime = item.activateGame.endAt ? convertTimeStampToHumanReadable(item.activateGame.endAt) : false;
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                              <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                              <td style={{fontFamily:'Open Sans' }}>{showEndTime.toString()}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.active.toString()}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.sentEmail ? item.sentEmail.toString() : "NO"}</td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id, true)}>DELETE</button></td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> Add Pledge</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                          <div className="col-sm-9">
                                              <input id="gameName" name="gameName" className="form-control" type="text" value={this.state.gameName} onChange={this.handleChange} placeholder="Example: 10/13 Game Dallas" />
                                          </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="name3"> Select Pledge</label>
                                          <div className="col-md-9">
                                            <select className="form-control" name="pledge_select" id="pledge_select" value={this.state.pledge_select} onChange={this.handleChange}>
                                              <option/>
                                              {
                                                this.state.pledgeList.map(function(item,index){
                                                  return(
                                                    <option value={item.key} key={index}>{item.pledgeName}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="form-group mb-3">
                                <ol>
                                  {
                                    this.state.pledgesAdded.map(function(item,index){
                                      return(
                                        <li key={index} style={{margin:10}}> {item.pledgeName}
                                          <span style={{marginLeft:"40px"}}>
                                            <button className="btn btn-danger btn-admin" style={{marginLeft:"40px"}} onClick={()=>this.removeFromToAddArray(index, "pledgesAdded")}><span className="fa fa-trash"/></button>
                                          </span>
                                        </li>
                                      )
                                    }, this)
                                  }
                                </ol>
                            </div>
                            <div className="form-group row mb-3" align="center">
                              <div className="col-md-12">
                                <button className="btn btn-primary btn-admin" onClick={()=>this.addPledgeToLocalArray()}>Add</button>
                              </div>
                            </div>
                        </div>

                         <div className="tab-pane" id="second">
                            <div className="form-horizontal">
                                <div className="row">
                                  <div className="col-12" style={{textAlign:'center'}}>
                                    <div className="form-check">
                                      <input value={this.state.noPrizes} className="form-check-input" id="noPrizes" name="noPrizes" type="checkbox" checked={this.state.noPrizes} onChange={this.handleChange} />
                                      <label className="form-check-label" htmlFor="codes">No Prizes</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                        <div className="col-md-9">
                                          <select className="form-control" onChange={this.saveReward}>
                                            <option/>
                                            {
                                              this.state.ticketList.map(function(item,index){
                                                return(
                                                    <option value={item.key} key={index}>{item.rewardName}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                        <div className="col-md-9">
                                          <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="email3">Redeem Codes?</label>
                                        <div className="col-md-9">
                                          <input value={this.state.codes} name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3" style={{display: this.state.codes && !tenantVariables.doNotCollectEmail ? 'flex' : 'none'}}>
                                        <label className="col-md-3 col-form-label" htmlFor="email3">Convert Codes To QRCode?</label>
                                        <div className="col-md-9">
                                          <input value={this.state.qrCodes} name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange} />
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                        <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                        <div className="col-md-9">
                                          <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                        <label htmlFor="pinsArray" className="col-md-3 col-form-label">Enter Pins</label>
                                        <div className="col-md-9">
                                          <textarea value={this.state.pinsArray} className="form-control" name="pinsArray" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3" align="center">
                                        <div className="col-md-12">
                                          <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                        </div>
                                      </div>

                                      <div className="form-group mb-3">
                                        <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                          {
                                            rewardsAdded.map(function(item,index){
                                              let codesText = "No"
                                              if(item.codes){
                                                codesText = "Yes"
                                              }
                                              return(
                                                  <div key={index} className="form-group">
                                                    <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index, "rewardsAdded")}/></li>
                                                  </div>
                                              )
                                            })
                                          }
                                        </ol>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="form-check">
                                  <input value={this.state.schedule_game} className="form-check-input" id="schedule_game" name="schedule_game" type="checkbox" checked={this.state.schedule_game} onChange={this.handleChange} />
                                  <label className="form-check-label" htmlFor="schedule_game">Schedule Game</label>
                                </div>
                                <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                    <label className="col-md-3 col-form-label" htmlFor="schedule_game_time"> Game Start Time</label>
                                    <div className="col-md-9">
                                      <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time} onChange={date => this.setState({schedule_game_time: date})}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                  <label className="col-md-3 col-form-label" htmlFor="schedule_game_time_end"> Game End Time</label>
                                  <div className="col-md-9">
                                    <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time_end} onChange={date => this.setState({schedule_game_time_end: date})}/>
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>
                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation !== 'first' ? '' : 'none' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation !== 'third' ? '' : 'none' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
