import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import UploadImage from '../utils/UploadImage';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';

class SetUpPledges extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();

        this.state = {
            pledgeList: [],
            pledgeName: "",
            editImage: '',
            selectedItem: {},
            modal: false,
            editing: false,
            loading:true,
            gifSelectionImage: null,
            gifSelectionImagePreview: {}
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.pledgeListRef = base.syncState(`pledgeList`, {
        context: this,
        state: 'pledgeList',
        asArray: true
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.pledgeListRef);
      URL.revokeObjectURL(this.state.gifSelectionImagePreview.preview);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Cannot be uploaded',
            text: 'Make sure images are less than 2mbs and videos are less then 20mb and are an acceptable file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
            if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
            } else if(res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: 'There was some issue with the image upload, please re-upload and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            }
        })
    }

    createListItem() {
        const pledgeName = this.state.pledgeName;
        const pledgeList = this.state.pledgeList || [];
        const gifSelectionImage = this.state.gifSelectionImage || this.state.editImage;
        const pledgeId = appDatabasePrimaryFunctions.ref().push().key;
        const vm = this;
        if(!pledgeName || !gifSelectionImage){
            swal({
                title: 'Oh uh!',
                text: 'Make sure the name and selection image are added!',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
        }
        if(pledgeName.length > 50){
            swal({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        for(let listIndex in pledgeList){
            const pledge = pledgeList[listIndex];
            if(pledge.pledgeName === pledgeName && !this.state.selectedItem){
                swal({
                    title: 'Oh uh!',
                    text: "This name already exists! Pick another name",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }
        this.setState({
            loading:true
        })
        const pledge = {};
        pledge.pledgeName = pledgeName;
        pledge.gifSelectionImage = gifSelectionImage;
        if(!this.state.editing){
            pledge.id = pledgeId;
            base.post(`pledgeList/${pledgeId}`, {
                data: pledge,
                then(err){
                    if(!err){
                        vm.setState({
                            loading: false,
                            pledgeName: "",
                            gifSelectionImage: null,
                            gifSelectionImagePreview: {},
                            editImage: "",
                            modal:false
                        })
                        swal({
                            title: 'Created Successfully!',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        })
                    } else {
                        vm.setState({loading:false})
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        })
                        // console.log(err)
                    }
                }
            })
        } else if(this.state.editing) {
            const selectedItemId = this.state.selectedItem.id || this.state.selectedItem.key;
            pledge.id = selectedItemId;
            base.post(`pledgeList/${selectedItemId}`, {
                data: pledge,
                then(err){
                    if(!err){
                        vm.setState({
                            loading: false,
                            pledgeName: "",
                            gifSelectionImage: null,
                            gifSelectionImagePreview: {},
                            editImage: "",
                            modal:false
                        })
                        vm.setState({loading:false})
                        swal({
                            title: 'Edited Successfully!',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        })
                    } else {
                        vm.setState({loading:false})
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        })
                        // console.log(err)
                    }
                }
            })
        } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try refreshing the page and logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    editListItem(event){
        event.preventDefault();
        const array = this.state.pledgeList;
        const item = array[event.target.value];
        if(!item){
            swal({
                title: 'Oh uh!',
                text: 'Something went wrong, refresh the page and try again!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({
            modal: !this.state.modal,
            selectedItem: item,
            pledgeName: item.pledgeName,
            editingImagePreview: item.gifSelectionImage,
            editImage: item.gifSelectionImage,
            editing: true
        });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        selectedItem: {},
        pledgeName: "",
        editingImagePreview: null,
        editImage: '',
        editing: false,
      });
    }

    deleteListItem(e){
      e.preventDefault();
      const array = this.state.pledgeList;
      const index = array[e.target.value];
      swal({
            title: 'Delete?',
            text: 'Are you sure you want to do this?  You will no longer be able to use it in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.remove('pledgeList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    render() {
        const pledgeList = this.state.pledgeList;
        let selectedItem = this.state.selectedItem;
        const gifSelectionImagePreview = this.state.gifSelectionImagePreview;
        if(Object.keys(selectedItem).length === 0){
            selectedItem = null
        }

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Pledges</p>
                    <p className="admin-subheader-text">These are what picks will appear during your contest!</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        pledgeList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.pledgeName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.gifSelectionImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editListItem.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteListItem.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group" >
                            <label htmlFor="pledgeName">Name</label>
                            <input type="text" id="pledgeName" name="pledgeName" className="form-control" value={this.state.pledgeName} onChange={this.handleChange} placeholder="A unique name to help with identification"/>
                        </div>
                      <div className="form-group" align="center">
                        <label htmlFor="gifSelectionImage" style={{width:'100%'}}>Selection Image (Max width: 500px)</label>
                        <img src={this.state.editImage} width="auto" height="100px" style={{display: this.state.editing ? '' : 'none' }} alt=""/>
                        <Dropzone
                          className="dropzone dz-clickable"
                          accept="image/*"
                          onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'gifSelectionImage')}}
                          multiple={false}
                          maxSize={2200000}>
                          <div className="dz-message needsclick">
                            <span className="fa fa-cloud-upload text-muted" style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}/>
                            <h3 style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                            <img
                              style={{display: gifSelectionImagePreview.preview ? '' : 'none'}}
                              src={gifSelectionImagePreview.preview}
                              width="100px"
                              height="auto"
                              alt="Drop your image here"
                            />
                          </div>
                        </Dropzone>
                      </div>
                      <div className="form-group text-center">
                        <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createListItem()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpPledges
